<template>
    <h1 data-aos="zoom-in"><slot/></h1>

</template>

<script>
export default {
    name: "heroTitle",

}
</script>

<style scoped>

</style>
