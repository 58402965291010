<template>
    <svg xmlns="http://www.w3.org/2000/svg" width="49.411" height="73.261" viewBox="0 0 49.411 73.261">
        <g id="Icon" transform="translate(-0.021 -0.595)">
            <path id="Path" d="M.382.286A.879.879,0,0,0,.291.3C.264.3.235.307.208.313H.2C.267.3.334.292.4.284Z" transform="translate(24.01 1.814)" fill="none"/>
            <path id="Path-2" data-name="Path" d="M.321.288C.3.294.275.3.251.312L.214.328A1.813,1.813,0,0,1,.388.269.346.346,0,0,0,.321.288Z" transform="translate(23.511 1.942)" fill="none"/>
            <path id="Path-3" data-name="Path" d="M1.752,3.474A1.754,1.754,0,0,0,3.373,2.4,1.725,1.725,0,0,0,2.994.509,1.766,1.766,0,0,0,1.083.132a1.735,1.735,0,0,0,.67,3.342Z" transform="translate(7.967 10.419)" fill="none"/>
            <path id="Path-4" data-name="Path" d="M.392.244Q.3.3.211.353C.271.315.331.277.392.243Z" transform="translate(23.042 2.162)" fill="none"/>
            <path id="Path-5" data-name="Path" d="M.3.3.241.328c.04-.021.08-.039.12-.06C.34.28.317.288.3.3Z" transform="translate(23.223 2.062)" fill="none"/>
            <path id="Path-6" data-name="Path" d="M.362.121l0,.007C.343.164.329.2.316.237S.288.317.274.356.252.435.24.475h0A2.525,2.525,0,0,1,.362.121Z" transform="translate(21.894 3.579)" fill="none"/>
            <path id="Path-7" data-name="Path" d="M.311.3A.941.941,0,0,0,.2.3H.177C.259.3.342.292.426.292Z" transform="translate(24.29 1.786)" fill="none"/>
            <path id="Path-8" data-name="Path" d="M.295.3h0Z" transform="translate(24.404 7.066)" fill="none"/>
            <path id="Path-9" data-name="Path" d="M.2.324C.266.3.334.287.4.273.334.287.267.3.2.324Z" transform="translate(23.738 1.871)" fill="none"/>
            <path id="Path-10" data-name="Path" d="M1.752,3.474A1.754,1.754,0,0,0,3.373,2.4,1.725,1.725,0,0,0,2.994.51,1.766,1.766,0,0,0,1.083.132a1.735,1.735,0,0,0,.669,3.342Z" transform="translate(37.96 10.419)" fill="none"/>
            <path id="Path-11" data-name="Path" d="M.248.386C.281.326.317.266.355.211.316.268.281.326.248.386Z" transform="translate(22.134 3.059)" fill="none"/>
            <path id="Path-12" data-name="Path" d="M.252.342C.285.312.317.283.35.255.317.283.285.312.252.342Z" transform="translate(22.617 2.477)" fill="none"/>
            <path id="Path-13" data-name="Path" d="M.339.265a.785.785,0,0,0-.077.06C.25.333.24.343.229.352.276.315.324.279.373.245Z" transform="translate(22.82 2.313)" fill="none"/>
            <path id="Path-14" data-name="Path" d="M.3.3C.276.327.257.355.237.383h0C.278.323.321.269.366.214.341.242.319.27.3.3Z" transform="translate(22.277 2.85)" fill="none"/>
            <path id="Path-15" data-name="Path" d="M.309.285a.725.725,0,0,0-.06.071C.282.317.318.278.354.241.338.258.323.269.309.285Z" transform="translate(22.429 2.666)" fill="none"/>
            <path id="Path-16" data-name="Path" d="M.3.291C.284.326.267.364.251.4.281.331.316.262.351.2.334.226.316.258.3.291Z" transform="translate(22.012 3.282)" fill="none"/>
            <path id="Path-17" data-name="Path" d="M0,0H0A3.387,3.387,0,0,1,3.4,3.366,3.387,3.387,0,0,0,0,0Z" transform="translate(18.877 32.965)" fill="none"/>
            <path id="Path-18" data-name="Path" d="M.749.742A.746.746,0,0,1,0,0H0A.745.745,0,0,0,.748.741.745.745,0,0,0,1.5,0h0a.738.738,0,0,1-.219.524A.752.752,0,0,1,.749.742Z" transform="translate(29.733 46.357)" fill="none"/>
            <path id="Path-19" data-name="Path" d="M0,0H2.353v9.77H13.387V7.522H5.049A.75.75,0,0,1,4.4,7.152a.735.735,0,0,1,0-.741.75.75,0,0,1,.648-.371h8.338V3.869H5.049a.741.741,0,1,1,0-1.482h8.338V0H0Z" transform="translate(18.023 59.756)" fill="none"/>
            <path id="Path-20" data-name="Path" d="M.34.341C.313.314.288.284.262.256.288.285.313.314.34.341Z" transform="translate(22.468 6.223)" fill="#a2cbeb"/>
            <path id="Path-21" data-name="Path" d="M.336.344C.312.314.289.285.267.252.289.283.312.314.336.344Z" transform="translate(22.291 6.016)" fill="#a2cbeb"/>
            <path id="Path-22" data-name="Path" d="M.3.241C.3.3.3.356.3.414.3.336.3.26.307.183.3.2.3.222.3.241Z" transform="translate(21.753 4.308)" fill="#a2cbeb"/>
            <path id="Path-23" data-name="Path" d="M.3.3C.31.316.322.33.331.346.311.315.292.283.271.251.283.267.29.284.3.3Z" transform="translate(22.133 5.787)" fill="#a2cbeb"/>
            <path id="Path-24" data-name="Path" d="M.289.322C.3.4.308.471.324.544l0,.017A2.609,2.609,0,0,1,.275.035C.275.132.28.228.289.322Z" transform="translate(21.773 4.686)" fill="#a2cbeb"/>
            <path id="Path-25" data-name="Path" d="M.317.187A1.026,1.026,0,0,0,.3.29a.845.845,0,0,0-.016.1V.412C.292.336.3.259.319.185Z" transform="translate(21.785 4.006)" fill="#a2cbeb"/>
            <path id="Path-26" data-name="Path" d="M.336.391C.311.332.288.272.267.206.288.269.31.331.336.391Z" transform="translate(21.911 5.326)" fill="#a2cbeb"/>
            <path id="Path-27" data-name="Path" d="M.3.3A.059.059,0,0,0,.306.312c.008.016.017.03.025.046C.311.318.292.278.271.239A.565.565,0,0,1,.3.3Z" transform="translate(22.007 5.555)" fill="#a2cbeb"/>
            <path id="Path-28" data-name="Path" d="M4.114,5.905a10.167,10.167,0,0,1-3.157-.5A3.257,3.257,0,0,1,0,6.161l2.569,9.96v-7.9S12.82,8.292,13.459,0l-.115,0A10.192,10.192,0,0,1,4.114,5.905Z" transform="translate(11.227 8.845)" fill="#4550e6" opacity="0.219"/>
            <path id="Path-29" data-name="Path" d="M.206.295C.224.295.242.3.261.3H.4A1.453,1.453,0,0,1,.206.295Z" transform="translate(24.305 7.059)" fill="#a2cbeb"/>
            <path id="Path-30" data-name="Path" d="M.311.3h0C.3.3.3.3.292.295Z" transform="translate(23.605 6.939)" fill="#a2cbeb"/>
            <path id="Path-31" data-name="Path" d="M.331.414C.307.338.288.261.271.183A1.394,1.394,0,0,0,.331.414Z" transform="translate(21.837 5.083)" fill="#a2cbeb"/>
            <path id="Path-32" data-name="Path" d="M.265.266.337.331Z" transform="translate(22.67 6.418)" fill="#a2cbeb"/>
            <path id="Path-33" data-name="Path" d="M.314.233C.3.277.293.32.284.364.293.32.307.277.319.232Z" transform="translate(21.819 3.821)" fill="#a2cbeb"/>
            <path id="Path-34" data-name="Path" d="M.3.3h0Z" transform="translate(23.372 6.856)" fill="#a2cbeb"/>
            <path id="Path-35" data-name="Path" d="M.364.308C.323.3.28.3.238.289.282.294.323.3.364.308Z" transform="translate(23.995 7.033)" fill="#a2cbeb"/>
            <path id="Path-36" data-name="Path" d="M.3.3c.025.006.05.01.075.015C.325.305.277.295.228.28Z" transform="translate(23.713 6.97)" fill="#a2cbeb"/>
            <path id="Shape" d="M14.524,50.564a.755.755,0,0,1-.529-.217.738.738,0,0,1-.219-.525V47.717H10.7a.745.745,0,0,1-.748-.742V36.464H9a.75.75,0,0,1-.648-.371.736.736,0,0,1,0-.741A.75.75,0,0,1,9,34.981h.958v-.976A17.915,17.915,0,0,1,.5,13.8a18.182,18.182,0,0,1,35.293,0,17.916,17.916,0,0,1-9.455,20.2v.975h.958a.741.741,0,0,1,0,1.482h-.958V38.85h.958a.741.741,0,0,1,0,1.482h-.958V42.5H27.3a.742.742,0,0,1,0,1.483h-.958v2.99a.745.745,0,0,1-.748.742H22.518v2.105a.738.738,0,0,1-.219.525.751.751,0,0,1-.53.217Z" transform="translate(6.571 23.293)"/>
            <rect id="Rectangle" width="5.751" height="1.364" transform="translate(21.842 71.01)" fill="#4550e6" opacity="0.219"/>
            <path id="Shape-2" data-name="Shape" d="M9.95,33.5V32.063c0-.018,0-.034,0-.052a.58.58,0,0,0-.009-.078.39.39,0,0,0-.017-.071A.525.525,0,0,0,9.9,31.8c-.011-.024-.022-.046-.032-.068a.631.631,0,0,0-.033-.059c-.013-.021-.029-.041-.043-.059a.7.7,0,0,0-.046-.055.715.715,0,0,0-.054-.05c-.019-.015-.035-.03-.055-.044a.7.7,0,0,0-.071-.042c-.015-.009-.028-.018-.042-.025a16.588,16.588,0,0,1-5.171-3.806c-.13-.141-.258-.285-.384-.432A16.551,16.551,0,0,1,2.592,25.3c-.1-.161-.2-.328-.3-.492s-.194-.332-.285-.5a16.73,16.73,0,0,1-.857-1.829c-.07-.18-.14-.358-.2-.541A16.333,16.333,0,0,1,3.062,6.942a16.682,16.682,0,0,1,30.225,9.545,16.008,16.008,0,0,1-.267,2.924,16.614,16.614,0,0,1-9.255,11.983c-.014.007-.026.016-.04.023a.755.755,0,0,0-.073.045.425.425,0,0,0-.053.045.578.578,0,0,0-.057.049.559.559,0,0,0-.044.055c-.015.02-.031.041-.043.059a.421.421,0,0,0-.033.059.475.475,0,0,0-.032.068.424.424,0,0,0-.02.066.67.67,0,0,0-.018.07.415.415,0,0,0-.009.082.3.3,0,0,0,0,.049V33.5Z" transform="translate(8.073 24.776)" fill="#5faaa8"/>
            <path id="Path-37" data-name="Path" d="M.374,1.386,2.749,2.745a.752.752,0,0,0,1.1-.45A.738.738,0,0,0,3.5,1.461L1.123.1A.752.752,0,0,0,.1.371.738.738,0,0,0,.374,1.384Z" transform="translate(3.211 28.307)"/>
            <path id="Path-38" data-name="Path" d="M4.219.741A.737.737,0,0,0,4,.217.751.751,0,0,0,3.47,0H.727a.741.741,0,0,0,0,1.482H3.47A.745.745,0,0,0,4.219.741Z" transform="translate(0.021 40.183)"/>
            <path id="Path-39" data-name="Path" d="M2.749.1.375,1.457A.737.737,0,0,0,.1,2.469a.752.752,0,0,0,1.022.272L3.5,1.384A.738.738,0,0,0,3.5.1a.755.755,0,0,0-.748,0Z" transform="translate(3.211 50.696)"/>
            <path id="Path-40" data-name="Path" d="M3.5,1.461,1.13.1A.754.754,0,0,0,.377.1a.738.738,0,0,0,.005,1.29L2.757,2.745a.754.754,0,0,0,.758.011.738.738,0,0,0-.011-1.3Z" transform="translate(42.343 50.692)"/>
            <path id="Path-41" data-name="Path" d="M3.489,0H.748A.75.75,0,0,0,.1.371a.735.735,0,0,0,0,.741.75.75,0,0,0,.648.371h2.74a.75.75,0,0,0,.648-.371.735.735,0,0,0,0-.741A.75.75,0,0,0,3.489,0Z" transform="translate(45.195 40.183)"/>
            <path id="Path-42" data-name="Path" d="M.1,2.474a.753.753,0,0,0,1.024.271L3.5,1.386A.74.74,0,0,0,3.5.1a.756.756,0,0,0-.75,0L.375,1.459A.737.737,0,0,0,.1,2.474Z" transform="translate(42.35 28.307)"/>
            <path id="Shape-3" data-name="Shape" d="M5.874,24.738l-.283-1.1L3.306,14.781H3.249v0A3.219,3.219,0,1,1,6.5,11.561a3.164,3.164,0,0,1-.092.758,8.64,8.64,0,0,0,2.463.354A8.692,8.692,0,0,0,16.6,7.916a4.137,4.137,0,0,1-2.519-3.691v-.1a4.159,4.159,0,1,1,5.808,3.79,8.7,8.7,0,0,0,7.734,4.757,8.639,8.639,0,0,0,2.462-.355,3.205,3.205,0,0,1,1.4-3.464,3.275,3.275,0,0,1,3.765.179,3.2,3.2,0,0,1,1.06,3.583,3.247,3.247,0,0,1-3.071,2.165h-.056l-2.57,9.956a.75.75,0,0,1-.723.56.813.813,0,0,1-.186-.024.746.746,0,0,1-.46-.339.735.735,0,0,1-.083-.562l2.57-9.959a3.245,3.245,0,0,1-.959-.755A10.173,10.173,0,0,1,18.392,8.247c-.049,0-.1,0-.147,0h-.031l-.115,0A10.173,10.173,0,0,1,5.714,13.656a3.248,3.248,0,0,1-.959.755L7.325,24.37a.738.738,0,0,1-.083.563.746.746,0,0,1-.46.339A.713.713,0,0,1,6.6,25.3.747.747,0,0,1,5.874,24.738Z" transform="translate(6.47 0.596)"/>
            <path id="Path-43" data-name="Path" d="M1.63,5.076h0a1.856,1.856,0,0,0,.222.081c.006,0,.011,0,.018.007l.028.008c.048.014.1.025.146.036s.1.023.146.032.084.014.126.019.1.012.152.016.126.009.19.009H2.67A2.643,2.643,0,1,0,2.67,0C2.587,0,2.5,0,2.421.012L2.365.019c-.067.007-.134.017-.2.029L2.094.064c-.069.015-.136.032-.2.051L1.853.128c-.06.019-.117.041-.174.063s-.094.04-.141.06a1.073,1.073,0,0,0-.12.06L1.389.325c-.06.034-.12.071-.181.11l-.06.04C1.1.509,1.05.545,1,.582L.92.652C.893.675.855.711.823.739S.763.8.735.828.664.9.63.943C.618.956.607.97.6.984.55,1.04.507,1.1.466,1.154l-.023.035c-.038.06-.074.116-.108.175C.33,1.376.324,1.386.317,1.4c-.036.068-.07.136-.1.208L.21,1.619a2.507,2.507,0,0,0-.12.355C.078,2.017.064,2.06.055,2.1v.007c-.016.074-.027.15-.036.227,0,.024-.005.048-.007.073C.005,2.487,0,2.564,0,2.641a2.614,2.614,0,0,0,.054.526l0,.017c.017.079.037.156.06.232,0,.012.008.023.011.035.021.063.043.125.069.185.011.026.022.051.034.076s.038.081.06.119.044.084.068.125.038.063.06.094c.03.047.06.092.094.136s.045.06.069.092.068.081.1.119.051.06.078.085c.041.041.083.08.127.119.024.021.048.045.073.066A2.66,2.66,0,0,0,1.63,5.076Z" transform="translate(22.042 2.076)" fill="#4550e6" opacity="0.219"/>
            <path id="Path-44" data-name="Path" d="M0,0H0V9.771H2.353V0Z" transform="translate(18.023 59.757)" fill="#4550e6" opacity="0.219"/>
        </g>
    </svg>

</template>

<script>
export default {
    name: "Icon2"
}
</script>

<style scoped>

</style>
