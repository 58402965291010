<template>
    <svg xmlns="http://www.w3.org/2000/svg" width="36.778" height="71.592" viewBox="0 0 36.778 71.592">
        <g id="Icon" transform="translate(0 -0.021)">
            <path id="Shape" d="M10.321,37.826A.748.748,0,0,1,10.1,37.3V35.924H8.043a.744.744,0,0,1-.742-.747V27.541H6.791a.745.745,0,0,1,0-1.49H7.3v-.414A13.578,13.578,0,0,1,15.643.173a13.6,13.6,0,0,1,4.063,25.465v.415h.511a.745.745,0,0,1,0,1.49h-.511v1.391h.511a.746.746,0,0,1,0,1.491h-.511v1.228h.511a.745.745,0,0,1,0,1.49h-.512v2.033a.749.749,0,0,1-.217.528.74.74,0,0,1-.524.219H16.906V37.3a.743.743,0,0,1-.74.746H10.845A.74.74,0,0,1,10.321,37.826Z" transform="translate(4.966 5.104)"/>
            <rect id="Rectangle" width="3.837" height="0.629" transform="translate(16.552 41.027)" fill="rgba(69,80,230,0.16)"/>
            <path id="Shape-2" data-name="Shape" d="M7.3,24.56v-.877a.294.294,0,0,0,0-.049.507.507,0,0,0-.009-.082.353.353,0,0,0-.016-.069c-.008-.022-.013-.046-.022-.068a.641.641,0,0,0-.032-.067c-.01-.02-.021-.041-.032-.059a.654.654,0,0,0-.041-.057c-.015-.021-.032-.041-.048-.06s-.034-.032-.052-.049a.564.564,0,0,0-.055-.045.782.782,0,0,0-.069-.041.306.306,0,0,0-.043-.026A12.1,12.1,0,0,1,12.022,0h0a12.1,12.1,0,0,1,5.143,23.011.412.412,0,0,0-.042.026.671.671,0,0,0-.069.041.581.581,0,0,0-.059.046c-.018.016-.036.032-.052.049a.754.754,0,0,0-.047.059.573.573,0,0,0-.04.056.3.3,0,0,0-.033.06.318.318,0,0,0-.032.066.6.6,0,0,0-.02.068.374.374,0,0,0-.018.069c-.005.028-.006.054-.01.082s0,.032,0,.049v.877Z" transform="translate(6.449 6.596)" fill="#5faaa8"/>
            <path id="Path" d="M.742,3.493a.738.738,0,0,0,.524-.218.746.746,0,0,0,.217-.527V.725A.742.742,0,0,0,0,.725V2.748a.748.748,0,0,0,.218.527.74.74,0,0,0,.525.218Z" transform="translate(17.729 0.021)"/>
            <path id="Path-2" data-name="Path" d="M1.106,2.87a.739.739,0,0,0,1.012.272.747.747,0,0,0,.271-1.018l-1-1.752A.739.739,0,0,0,.371.1.748.748,0,0,0,.1,1.118Z" transform="translate(8.936 2.368)"/>
            <path id="Path-3" data-name="Path" d="M.37,1.391,2.112,2.4a.73.73,0,0,0,.37.1.746.746,0,0,0,.371-1.391L1.112.1A.739.739,0,0,0,.1.372.747.747,0,0,0,.37,1.39Z" transform="translate(2.5 8.84)"/>
            <path id="Path-4" data-name="Path" d="M.762,1.49H2.775a.74.74,0,0,0,.659-.366.749.749,0,0,0,0-.757A.74.74,0,0,0,2.775,0H.762A.74.74,0,0,0,.1.367a.749.749,0,0,0,0,.757A.74.74,0,0,0,.762,1.49Z" transform="translate(0.121 17.683)"/>
            <path id="Path-5" data-name="Path" d="M3.127.375A.74.74,0,0,0,2.112.1L.371,1.112A.747.747,0,0,0,.1,2.13.739.739,0,0,0,1.112,2.4L2.854,1.391A.746.746,0,0,0,3.127.375Z" transform="translate(2.498 25.512)"/>
            <path id="Path-6" data-name="Path" d="M2.5,2.509a.746.746,0,0,0,.372-1.391L1.127.109A.738.738,0,0,0,.375.1a.747.747,0,0,0,.01,1.3L2.128,2.41a.734.734,0,0,0,.369.1Z" transform="translate(31.204 25.505)"/>
            <path id="Path-7" data-name="Path" d="M0,.745a.744.744,0,0,0,.742.745H2.752a.745.745,0,0,0,0-1.49H.742A.739.739,0,0,0,.217.218.747.747,0,0,0,0,.745Z" transform="translate(33.305 17.684)"/>
            <path id="Path-8" data-name="Path" d="M.74,2.511a.729.729,0,0,0,.37-.1L2.853,1.4A.747.747,0,0,0,2.861.1a.738.738,0,0,0-.75.011L.37,1.119A.747.747,0,0,0,.74,2.511Z" transform="translate(31.22 8.834)"/>
            <path id="Path-9" data-name="Path" d="M.372,3.144a.749.749,0,0,0,.37.1.738.738,0,0,0,.642-.374l1.006-1.75a.749.749,0,0,0,0-.745.739.739,0,0,0-1.284,0L.1,2.124A.75.75,0,0,0,.372,3.144Z" transform="translate(25.517 2.37)"/>
            <path id="Shape-3" data-name="Shape" d="M30.159,28H6.5a.74.74,0,0,1-.7-.513L1.783,15.12A2.458,2.458,0,0,1,0,12.757v-.3A2.453,2.453,0,0,1,2.447,10H17.729V.725a.742.742,0,0,1,1.483,0V10h15a2.453,2.453,0,0,1,2.446,2.455v.3a2.462,2.462,0,0,1-1.784,2.363l-4.01,12.369a.741.741,0,0,1-.7.513Z" transform="translate(0 43.615)"/>
            <path id="Path-10" data-name="Path" d="M33.692.968v.3a.965.965,0,0,1-.961.967H.964A.966.966,0,0,1,0,1.268v-.3A.968.968,0,0,1,.964,0H32.729A.966.966,0,0,1,33.692.968Z" transform="translate(1.482 55.103)" fill="rgba(69,80,230,0.18)"/>
            <path id="Shape-4" data-name="Shape" d="M3.946,8.188C.587,6.035.025,1.66,0,1.475A.533.533,0,0,1,0,1.42c0-.023,0-.043,0-.07s.006-.051.01-.077.006-.043.011-.064a.728.728,0,0,1,.025-.075.491.491,0,0,1,.023-.06c.011-.023.023-.044.035-.066S.13.968.142.949A.416.416,0,0,1,.187.9.608.608,0,0,1,.238.841.64.64,0,0,1,.293.8C.313.782.332.768.353.754A.724.724,0,0,1,.422.718C.437.711.45.7.464.7.637.626,4.723-.983,8.216.944c2.2,1.211,3.625,3.515,4.25,6.85a.135.135,0,0,1,0,.027.433.433,0,0,1,.006.048v.1a.233.233,0,0,1,0,.043.327.327,0,0,1-.006.049c0,.018-.006.035-.011.053l-.012.041c0,.015-.01.03-.015.045a.455.455,0,0,1-.022.048.242.242,0,0,1-.022.043.115.115,0,0,1-.01.022.132.132,0,0,1-.016.025l-.021.029a.066.066,0,0,1-.01.014.082.082,0,0,0-.006.012l-.055.06s-.006.007-.01.01l-.023.021a.3.3,0,0,0-.024.02l-.051.04-.027.017c-.007,0-.016.011-.02.012s-.005.007-.01.007a.613.613,0,0,1-.074.035l-.013.007-.034.011-.013.007a12.663,12.663,0,0,1-3.911.691H7.915A7.46,7.46,0,0,1,3.946,8.188Z" transform="translate(4.549 42.64)"/>
            <path id="Shape-5" data-name="Shape" d="M4.417,9.331A12.609,12.609,0,0,1,.508,8.64L.492,8.633.46,8.622.446,8.616C.421,8.6.4,8.593.372,8.581a.019.019,0,0,0-.01-.007L.344,8.561.317,8.545C.3,8.532.283,8.518.266,8.5L.242,8.483a.247.247,0,0,1-.023-.02l-.01-.011Q.18,8.424.155,8.393a.034.034,0,0,1-.008-.012L.138,8.368l-.021-.03A.216.216,0,0,1,.1,8.312a.146.146,0,0,1-.01-.021.374.374,0,0,1-.022-.042A.375.375,0,0,1,.048,8.2c-.005-.015-.012-.03-.016-.044S.024,8.131.021,8.114.013,8.077.01,8.062A.245.245,0,0,1,0,8.015V7.97a.5.5,0,0,1,0-.052.422.422,0,0,1,0-.048.406.406,0,0,1,.006-.048l0-.027C.635,4.46,2.064,2.155,4.26.945,7.752-.984,11.839.626,12.01.7a.279.279,0,0,1,.051.027l.06.03A.581.581,0,0,1,12.19.8c.016.013.031.024.047.039A.581.581,0,0,1,12.292.9a.381.381,0,0,1,.04.046.724.724,0,0,1,.042.066c.01.021.023.041.031.06a.579.579,0,0,1,.026.069.423.423,0,0,1,.016.067c.005.023.011.052.013.074a.568.568,0,0,1,.008.068.608.608,0,0,1,0,.081v.047c-.022.186-.586,4.561-3.943,6.714A7.474,7.474,0,0,1,4.561,9.333Z" transform="translate(19.633 42.637)"/>
        </g>
    </svg>

</template>

<script>
export default {
    name: "Icon4"
}
</script>

<style scoped>

</style>
