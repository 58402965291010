<template>
    <h2 class="lg:max-w-xl lg:text-left tracking-wider  mb-3 lg:mb-0 leading-4 2xl:leading-8"><slot/></h2>

</template>

<script>
export default {
    name: "heroDescription"
}
</script>

<style scoped>

</style>
