<template>

    <svg xmlns="http://www.w3.org/2000/svg" width="47.862" height="65.235" viewBox="0 0 47.862 65.235">
        <path id="Path" d="M19.144,49.3H10.713V39.734a.7.7,0,0,0-.2-.491.681.681,0,0,0-.486-.2H2.612c2.6-2.488,4.489-6.2,5.507-10.872a34.8,34.8,0,0,0,.81-7.663,18.782,18.782,0,1,1,37.562.009V31.578a.686.686,0,1,0,1.372,0V20.513A20.457,20.457,0,0,0,37.816,2.752a19.937,19.937,0,0,0-20.214,0A20.457,20.457,0,0,0,7.557,20.513v.021c0,.132.293,13.281-7.249,18.62l-.013.008h0a.763.763,0,0,0-.1.088c-.007.008-.013.017-.019.024a.583.583,0,0,0-.064.083c-.006.01-.01.02-.017.03a.787.787,0,0,0-.044.089.315.315,0,0,0-.01.032.673.673,0,0,0-.027.1c0,.013,0,.027,0,.043A.679.679,0,0,0,0,39.732v.008c0,.016,0,.029,0,.042a.69.69,0,0,0,.117.345v0a.716.716,0,0,0,.07.084.128.128,0,0,0,.018.017.651.651,0,0,0,.2.135l.021.011a.566.566,0,0,0,.114.036H.557a.628.628,0,0,0,.13.013H9.34v9.564a.691.691,0,0,0,.686.695h8.432V64.541a.686.686,0,1,0,1.372,0V49.993a.7.7,0,0,0-.2-.491A.681.681,0,0,0,19.144,49.3Z"/>
        <svg class="border-2" x="20px" y="32px" xmlns="http://www.w3.org/2000/svg" width="33.685" height="29.278" viewBox="0 0 33.685 29.278" >
            <path id="Shape" d="M4.7,25a.7.7,0,0,0-.2-.492.682.682,0,0,0-.486-.2h-2.4A1.629,1.629,0,0,1,0,22.669V1.639A1.63,1.63,0,0,1,1.618,0H32.067a1.63,1.63,0,0,1,1.618,1.638v21.03a1.631,1.631,0,0,1-1.618,1.639H13.1c-.014,0-.027,0-.039,0a.617.617,0,0,0-.081.008.316.316,0,0,0-.061.014c-.021.008-.042.013-.066.022s-.051.022-.066.03-.028.013-.04.021L4.7,29.278Z" fill="#5faaa8"/>
        </svg>
    </svg>



</template>

<script>
export default {
    name: "Icon1"
}
</script>

<style scoped>

</style>
