<template>
    <svg xmlns="http://www.w3.org/2000/svg" width="66.854" height="73.638" viewBox="0 0 66.854 73.638">
        <g id="Icon" transform="translate(-0.594 -0.002)">
            <g id="Group">
                <path id="Shape" d="M10.755,30.817A2.566,2.566,0,0,1,8.5,27.029L.785,19.3a2.762,2.762,0,0,1,3.9-3.906l2.266,2.229a.052.052,0,0,0,.009.011l.008.007a.762.762,0,0,0,.555.239.776.776,0,0,0,.78-.769V2.762a2.761,2.761,0,1,1,5.522,0V9.37a2.649,2.649,0,0,1,1.279-.336A2.785,2.785,0,0,1,17.515,10.5a2.747,2.747,0,0,1,4.009.814,2.752,2.752,0,0,1,4.423,2.063c0,.023.005.044.007.067V23.8a3.354,3.354,0,0,1-.982,2.372v0a2.566,2.566,0,0,1-1.507,4.643Z" transform="translate(17.658 3.287)"/>
                <path id="Path" d="M23.014,12.029V22.318A1.881,1.881,0,0,1,21.135,24.2H9.3a2.551,2.551,0,0,0-1.2.3L.373,16.775a1.28,1.28,0,1,1,1.814-1.806L4.459,17.2l.007.006a2.257,2.257,0,0,0,3.866-1.574V1.281a1.28,1.28,0,1,1,2.56,0v10.8a.74.74,0,1,0,1.48,0v-1.7a1.281,1.281,0,1,1,2.559,0v2.29a.741.741,0,0,0,1.482,0V11.24a1.279,1.279,0,1,1,2.559,0v2.291a.741.741,0,0,0,1.482,0V12.025a1.279,1.279,0,1,1,2.559,0Z" transform="translate(19.115 4.769)" fill="#5faaa8"/>
                <path id="Path-2" data-name="Path" d="M.452,2.112A.741.741,0,1,0,1.845,1.6L1.433.475A.741.741,0,0,0,.04.981Z" transform="translate(26.23 0.014)"/>
                <path id="Path-3" data-name="Path" d="M.37,1.383l1.041.6A.741.741,0,0,0,2.153.7L1.112.1A.741.741,0,0,0,.37,1.383Z" transform="translate(23.527 2.265)"/>
                <path id="Path-4" data-name="Path" d="M.742,1.689a.741.741,0,0,0,.13-.011l1.185-.209A.741.741,0,0,0,1.8.009L.615.218A.741.741,0,0,0,.742,1.689Z" transform="translate(22.913 5.527)"/>
                <path id="Path-5" data-name="Path" d="M.011.613a.741.741,0,0,0,.6.858L1.8,1.68a.726.726,0,0,0,.128.011A.742.742,0,0,0,2.054.22L.869.011A.741.741,0,0,0,.011.613Z" transform="translate(31.871 5.526)"/>
                <path id="Path-6" data-name="Path" d="M.742,2.084a.741.741,0,0,0,.369-.1l1.041-.6A.741.741,0,0,0,1.412.1L.371.7A.741.741,0,0,0,.742,2.084Z" transform="translate(31.402 2.265)"/>
                <path id="Path-7" data-name="Path" d="M.486,2.564A.727.727,0,0,0,.74,2.61a.744.744,0,0,0,.7-.488L1.847.991A.741.741,0,0,0,.455.484L.044,1.618a.74.74,0,0,0,.442.946Z" transform="translate(29.338 0.002)"/>
                <path id="Path-8" data-name="Path" d="M.741,0A.742.742,0,0,0,0,.741V38.346a.741.741,0,0,0,1.482,0V.741A.741.741,0,0,0,.741,0Z" transform="translate(27.611 34.553)"/>
                <path id="Path-9" data-name="Path" d="M.743,0A.74.74,0,0,0,0,.741V38.346a.741.741,0,0,0,1.481,0V.741A.74.74,0,0,0,.743,0Z" transform="translate(40.442 34.553)"/>
                <path id="Shape-2" data-name="Shape" d="M21.766,28.589H10.023a2.425,2.425,0,0,1-2.156-3.546l-7.1-7.105a2.609,2.609,0,0,1,3.688-3.69l2.1,2.061.006.007a.1.1,0,0,0,.01.009.648.648,0,0,0,.472.2.66.66,0,0,0,.665-.653V2.61a2.609,2.609,0,1,1,5.217,0V8.627a2.485,2.485,0,0,1,1.126-.275A2.625,2.625,0,0,1,16.283,9.68a2.6,2.6,0,0,1,3.705.752,2.6,2.6,0,0,1,4.126,1.989.442.442,0,0,1,.009.06v9.568a3.151,3.151,0,0,1-.883,2.184,2.426,2.426,0,0,1-1.47,4.356Z" transform="translate(0.594 22.477)"/>
                <path id="Path-10" data-name="Path" d="M21.157,11.086v9.507a1.683,1.683,0,0,1-1.68,1.682H8.54a2.4,2.4,0,0,0-1.1.268L.328,15.433a1.127,1.127,0,1,1,1.6-1.591l2.1,2.065.01.009a2.142,2.142,0,0,0,3.664-1.5V1.153a1.127,1.127,0,1,1,2.253,0V11.13a.741.741,0,0,0,1.482,0V9.56a1.127,1.127,0,1,1,2.251,0v2.119a.741.741,0,1,0,1.482,0V10.352a1.127,1.127,0,0,1,2.253,0V12.47a.741.741,0,1,0,1.482,0V11.076a1.127,1.127,0,0,1,2.254.01Z" transform="translate(2.077 23.933)" fill="rgba(95,170,168,0.22)"/>
                <path id="Path-11" data-name="Path" d="M.741,0A.741.741,0,0,0,0,.742V21.527a.741.741,0,0,0,1.482,0V.742A.741.741,0,0,0,.741,0Z" transform="translate(9.817 51.372)"/>
                <path id="Path-12" data-name="Path" d="M.741,0A.742.742,0,0,0,0,.742V21.527a.741.741,0,1,0,1.482,0V.742A.74.74,0,0,0,.741,0Z" transform="translate(21.676 51.372)"/>
                <path id="Shape-3" data-name="Shape" d="M21.767,28.589H10.023a2.425,2.425,0,0,1-2.155-3.546l-7.1-7.105a2.609,2.609,0,0,1,3.688-3.69l2.1,2.061s0,0,.006.007.006.005.01.009a.646.646,0,0,0,.472.2.674.674,0,0,0,.472-.191.653.653,0,0,0,.194-.463V2.61a2.609,2.609,0,1,1,5.217,0V8.627a2.485,2.485,0,0,1,1.125-.275,2.627,2.627,0,0,1,2.24,1.328,2.6,2.6,0,0,1,3.705.752,2.6,2.6,0,0,1,4.127,1.989.592.592,0,0,1,.006.06v9.568a3.156,3.156,0,0,1-.88,2.184h0a2.427,2.427,0,0,1-1.473,4.356Z" transform="translate(43.251 22.477)"/>
                <path id="Path-13" data-name="Path" d="M21.155,11.059v9.507a1.683,1.683,0,0,1-1.679,1.682H8.538a2.408,2.408,0,0,0-1.1.268L.328,15.407a1.127,1.127,0,1,1,1.6-1.591l2.1,2.065s.007,0,.01.01a2.142,2.142,0,0,0,3.665-1.5V1.127a1.126,1.126,0,1,1,2.252,0V11.1a.741.741,0,0,0,1.482,0V9.536a1.128,1.128,0,1,1,2.253,0v2.119a.741.741,0,1,0,1.482,0V10.322a1.127,1.127,0,1,1,2.254.006v2.116a.741.741,0,0,0,1.481,0v-1.4a1.126,1.126,0,1,1,2.251.012Z" transform="translate(44.734 23.96)" fill="rgba(95,170,168,0.22)"/>
                <path id="Path-14" data-name="Path" d="M.741,0A.741.741,0,0,0,0,.742V21.527a.741.741,0,0,0,1.481,0V.742A.74.74,0,0,0,.741,0Z" transform="translate(52.474 51.372)"/>
                <path id="Path-15" data-name="Path" d="M.741,0A.74.74,0,0,0,0,.742V21.527a.741.741,0,0,0,1.481,0V.742A.74.74,0,0,0,.741,0Z" transform="translate(64.336 51.372)"/>
            </g>
            <rect id="Rectangle" width="1.902" height="20.731" transform="translate(11.299 52.113)" fill="rgba(69,80,230,0.22)"/>
            <rect id="Rectangle-2" data-name="Rectangle" width="2.218" height="20.732" transform="translate(53.955 52.14)" fill="rgba(69,80,230,0.22)"/>
            <rect id="Rectangle-3" data-name="Rectangle" width="2.408" height="37.627" transform="translate(29.093 35.217)" fill="rgba(69,80,230,0.22)"/>
        </g>
    </svg>

</template>

<script>
export default {
    name: "Icon3"
}
</script>

<style scoped>

</style>
